@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@layer utilities {
    /* Custom scrollbar styles */
    .scrollbar-light {
      scrollbar-width: auto; /* Firefox */
      /* scrollbar-color: #4B5563 #E5E7EB; thumb color and track color */
    }
    .scrollbar-light::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    .scrollbar-light::-webkit-scrollbar-thumb {
      background-color: #777777; /* Thumb color */
      border-radius: 100%;
    }
    .scrollbar-light::-webkit-scrollbar-track {
      background-color: #E5E7EB; /* Track color */
    }
    .scrollbar-x-sm {
      /* For modern browsers */
      scrollbar-width: auto; /* Firefox */
      /* scrollbar-color: #d2d2d2 transparent; */
    }
    /* Webkit styles for Chrome, Edge, Safari */
    .scrollbar-x-sm::-webkit-scrollbar {
      width: 3px; /* Width of the scrollbar */
      height: 3px; /* Height of the scrollbar */
    }

    .scrollbar-x-sm::-webkit-scrollbar-thumb {
      background-color: #4B5563; /* Color of the thumb */
      border-radius: 1.5px; /* Rounded corners */
      width: 2px;
      height: 2px;
    }

    .scrollbar-x-sm::-webkit-scrollbar-track {
      background-color: #E5E7EB; /* Background of the track */
      border-radius: 10px; /* Rounded corners */
      margin: 0 160px; /* Center the track */
    }

    .boxShadow{
      box-shadow: 0px 5px #6DE35F;
    }

    .boxShadow-gray{ 
      /* opacity */
      box-shadow: 0px 7px rgba(0, 0, 0, 0.1);
    }

    .boxShadow-border{ 
      /* opacity */
      box-shadow: 0px -7px rgba(0, 0, 0, 0.1);
    }

    .boxShadow-right{
      box-shadow: 3px 3px #6DE35F;
    }
    
  }